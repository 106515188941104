.conteiner {
  background-color: #eeeeee;
}
footer {
  margin-top: 40px;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  background-color: #0000008c;
}
footer ul {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

footer p {
  color: white;
  height: 20px;
}
footer img {
  width: 50px;
  height: 50px;
}
.linkedin {
  transform: scale(1.2);
}
@media screen and (max-width: 960px) {
  .objetivos {
    padding: 50px 0 0 0;
  }

  .objetivos div:first-child::after {
    width: 350px;
    height: 300px;
  }
  .objetivos h1 {
    font-size: 1.2rem;
  }
  .objetivos p,
  .objetivos cite {
    font-size: 0.8em;
  }
  .area-atuacao article:hover {
    transform: scale(1.1);
  }
  footer img {
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 736px) {
  .objetivos div:first-child::after,
  .sobre .fotoFran {
    display: none;
  }
  .objetivos div:first-child {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
  .objetivos blockquote {
    width: 75%;
  }
  .area-atuacao {
    padding: 8px;
    margin: 0 0 0 0;
  }
  .area-atuacao article {
    width: 125px;
    height: 110px;
  }
  .area-atuacao article img {
    width: 30px;
  }
  .descritivo-atuacao {
    font-size: 18px;
  }
  .sobre {
    background-size: cover;
    padding: 80px 10px;
  }
  .sobre .descricao {
    grid-template-columns: 1fr;
    width: 95%;
  }
  .sobre .descricao h1,
  .sobre .descricao p {
    padding: 0 30px;
    text-align: left;
  }
  .contatos {
    width: 70%;
  }
  footer p {
    font-size: 0.8rem;
  }
  footer ul {
    width: 30%;
  }
}
@media screen and (max-width: 505px) {
  .descritivo-atuacao {
    font-size: 15px;
  }
  .sobre {
    padding: 60px 10px;
  }
  footer p {
    display: none;
  }
  footer ul {
    width: 80%;
  }
}

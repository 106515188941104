@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  font: 400 14px Montserrat, sans-serif;
  background: #f0f0f5;
  -webkit-font-smoothing: antialiased;
}

input,
button,
textarea {
  font: 400 14px Montserrat, sans-serif;
}

button {
  cursor: pointer;
}
.header {
  position: fixed;
  z-index: 1;
  width: 100%;
  display: grid;
  grid-template-areas: "logoheader nav";
  background-color: #000;
  border-bottom: 2px solid white;
  padding: 5px 40px 5px 20px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.nav {
  grid-area: nav;
  width: 400px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.nav a {
  color: white;
}

.logo-header {
  grid-area: logoheader;
  max-width: 50px;
  width: auto;
}

.burger {
  grid-area: burger;
  display: none;
  justify-self: end;
  width: 40px;
  height: 40px;
  background-color: #ffffff00;
  border: 0;
}

.burger span {
  position: relative;
  display: block;
  background: #fff;
  width: 35px;
  height: 3px;
  top: 0;
  left: 0;
  border-radius: 100px;
}

.burger span::after,
.burger span::before {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 100px;
}

.burger span::after {
  bottom: -8px;
}

.burger span::before {
  bottom: 8px;
}

.nav-animation-enter {
  opacity: 0;
}

.nav-animation-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: opacity 350ms;
}

.nav-animation-exit {
  opacity: 1;
}

.nav-animation-exit-active {
  opacity: 0;
  transition: opacity 350ms;
}

.nav .link {
  padding: 6px;
  border-radius: 8px;
}

.nav .link:hover {
  color: #bda27fc7;
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .header {
    grid-template-areas: "logoheader burger"" nav nav";
    grid-row-gap: 10px;
    row-gap: 10px;

    padding: 8px;
  }

  .logo-header {
    width: 120px;
    height: 50px;
  }

  .nav {
    width: auto;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 150px;
  }

  .burger {
    display: block;
  }
}
.home {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.233),
        rgba(0, 0, 0, 0.651)
      ),
      url(/static/media/lawyer-welcome.3441dd96.jpeg);
    background-size: cover;
    /* background-attachment: fixed; */
  }
  .home .descritivo-home {
    text-align: center;
    width: 25%;
    padding: 35px 10px;
    margin-left: 180px;
    border: 1px solid white;
    border-radius: 8px;
    box-shadow: inset 0 0 3px 1px black;
  }
  .home .descritivo-home p {
    color: white;
    font-size: 25px;
    text-shadow: 2px 2px 2px black;
  }
  @media screen and (max-width: 736px) {
    .home .descritivo-home {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
    }
  }
.objetivos {
  position: relative;
  padding: 80px 15px;

  overflow: hidden;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: space-around;
          justify-content: space-around;

  font-size: 1.1em;
}

.logo-body {
  max-width: 350px;
  min-width: 120px;
  width: auto;
  margin-bottom: 24px;

}

.objetivos div:first-child {
  width: 95%;
  padding: 10px 20px;
  border-left: 2px solid rgba(0, 0, 0, 0.432);
}

.objetivos blockquote {
  width: 60%;
  padding: 15px;
}

.objetivos blockquote p::after,
.objetivos blockquote p::before {
  content: '"';
}

.objetivos blockquote cite {
  float: right;
}

/* .objetivos div:first-child::after {
  position: absolute;
  box-shadow: 0 0 15px 0 black inset;
  top: -70px;
  left: -70px;
  content: " ";
  width: 450px;
  height: 400px;
  border-radius: 100%;
  background-image: url("../../assents/book.jpeg");
  background-size: 450px 400px;
} */

.objetivos div p::first-letter {
  margin-left: 12px;
}

.objetivos h1 {
  font-size: 1.6rem;
  padding-bottom: 8px;
}

.objetivos p {
  padding: 5px 0;
}

@media screen and (max-width: 960px) {

  .logo-body {
    max-width: 200px;
  }

}
.sobre {
  position: relative;
  padding: 50px;
  text-align: center;

  background-image: linear-gradient(to top,
      rgba(0, 0, 0, 0.288),
      rgba(0, 0, 0, 0.507)),
    url(/static/media/background-home.ada77926.jpeg);
  background-size: 100% 110%;
}

.sobre .descricao {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  text-shadow: 1px 1px 1px black;

  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.sobre .descricao::before {
  position: absolute;
}

.sobre .descricao div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.sobre .descricao p {
  text-align: left;
  font-size: 1.2em;
  padding: 5px 60px;
  color: white;
}

.sobre .descricao h1 {
  color: white;
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.sobre .fotoFran {
  border-radius: 8px;
  /* border: 2px solid #5f5f5f; */
  box-shadow: 0 0 16px;
  width: 100%;
}
.contatos {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.contatos h1 {
  font-size: 1.6rem;
  padding: 40px 0;
}
.contatos form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.contatos form :nth-child(n):not(p) {
  font-size: 1.2em;
  border: 1px solid rgb(190, 189, 189);
  box-shadow: 0 0 5px rgb(190, 189, 189);
  border-radius: 8px;
  padding: 10px;
  margin: 8px 0;
}
.contatos form :nth-child(n):not(.button) {
  width: 100%;
}
.contatos form p {
  color: #e02020;
  padding: 0 10px;
  font-size: 0.9em;
}
.contatos form p::before,
.contatos form p::after {
  content: " *** ";
}
.contatos form .messenger {
  height: 10em;
  word-wrap: break-word;
  box-sizing: border-box;
}

.contatos form .button {
  border: 0 !important;
  border-radius: 8px !important;
  box-shadow: 2px 2px 3px 1px #705c4294 !important;
  background-color: #af9068;
  width: 30%;
  color: #000;
  font-weight: 500;
  cursor: pointer;

  transition: box-shadow .3s ease, -webkit-transform .3s ease;

  transition: box-shadow .3s ease, transform .3s ease;

  transition: box-shadow .3s ease, transform .3s ease, -webkit-transform .3s ease;
}
.contatos form .button:active {
  box-shadow: 0 0 0 0 #705c4294, inset 4px 4px 2px #705c4294!important;
  -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px);
}
.area-atuacao {
  width: 95%;

  position: relative;
  margin: 20px auto;
  padding: 30px;

  border-radius: 8px;
  background-color: white;
  text-align: center;
}

.area-atuacao h1 {
  font-size: 1.6rem;
  margin-bottom: 20px;
}

.area-atuacao .icons {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.area-atuacao article {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;

  width: 200px;
  height: 150px;
  padding: 5px;
  margin: 5px;
  border: 2px solid #69563d94;
  border-radius: 8px;
  cursor: pointer;
}

.area-atuacao article:hover {
  background-image: linear-gradient(to top, #af90684b, #af906833),
    url(/static/media/background-texture.8b434523.png);
  background-size: cover;
}

.descritivo-atuacao {
  font-size: 1rem;
}

.area-atuacao article img {
  width: 50px;
}

.area-atuacao article h1 {
  font-size: 1em;
}

.area-atuacao article p {
  display: none;
}
.conteiner {
  background-color: #eeeeee;
}
footer {
  margin-top: 40px;
  padding: 20px;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;

  background-color: #0000008c;
}
footer ul {
  width: 20%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

footer p {
  color: white;
  height: 20px;
}
footer img {
  width: 50px;
  height: 50px;
}
.linkedin {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
@media screen and (max-width: 960px) {
  .objetivos {
    padding: 50px 0 0 0;
  }

  .objetivos div:first-child::after {
    width: 350px;
    height: 300px;
  }
  .objetivos h1 {
    font-size: 1.2rem;
  }
  .objetivos p,
  .objetivos cite {
    font-size: 0.8em;
  }
  .area-atuacao article:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  footer img {
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 736px) {
  .objetivos div:first-child::after,
  .sobre .fotoFran {
    display: none;
  }
  .objetivos div:first-child {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
  .objetivos blockquote {
    width: 75%;
  }
  .area-atuacao {
    padding: 8px;
    margin: 0 0 0 0;
  }
  .area-atuacao article {
    width: 125px;
    height: 110px;
  }
  .area-atuacao article img {
    width: 30px;
  }
  .descritivo-atuacao {
    font-size: 18px;
  }
  .sobre {
    background-size: cover;
    padding: 80px 10px;
  }
  .sobre .descricao {
    grid-template-columns: 1fr;
    width: 95%;
  }
  .sobre .descricao h1,
  .sobre .descricao p {
    padding: 0 30px;
    text-align: left;
  }
  .contatos {
    width: 70%;
  }
  footer p {
    font-size: 0.8rem;
  }
  footer ul {
    width: 30%;
  }
}
@media screen and (max-width: 505px) {
  .descritivo-atuacao {
    font-size: 15px;
  }
  .sobre {
    padding: 60px 10px;
  }
  footer p {
    display: none;
  }
  footer ul {
    width: 80%;
  }
}


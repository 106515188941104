@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  font: 400 14px Montserrat, sans-serif;
  background: #f0f0f5;
  -webkit-font-smoothing: antialiased;
}

input,
button,
textarea {
  font: 400 14px Montserrat, sans-serif;
}

button {
  cursor: pointer;
}